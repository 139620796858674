.register-form-wrapper {
    display: flex;
    width: 80%;
    align-items: center;
    margin-top: 3rem;
    gap: 2rem;
}

.left-column {
    flex: 0.2;
    justify-content: right;
    align-items: right;

}

.right-column {
    flex: 0.8;
}

.left-column img {
    width: 180px;
    margin-bottom: 20px;
}

.left-column .title {
    font-size: 24px;
    font-weight: bold;
}

.left-column .subtitle {
    font-size: 16px;
    color: gray;
}

.right-column form {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem
}

.input-group-left,
.input-group-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    border: 1px solid lightgray;
    padding: 1rem;
    border-radius: 0.5rem;
    justify-content: space-between;
    flex: 1;
    flex-grow: 1;
    gap: 1rem;
}


form input,
form select,
form button {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
   /* flex-grow: 1;*/
}

form input:focus{
    border: 1px solid var(--primary-color);
}

form button {
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;
    color: white;
}

form button {
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;
    color: white;
}

form button:hover {
    background: var(--focus-color);
}

.form-subgroup{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

.form-subgroup.item{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.form-subgroup.item label{
    width: 50%;
    text-align: left;
}

.form-subgroup.item input{
    width: 30%;
    text-align: right;
    flex-grow: 0.5;
}


.form-map-container {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    border-radius: 0.3rem;
}

.hex-bg {
    display: flex;
    justify-content: center;
    align-items: center;

    background-size: cover;
    height: 100vh;
}

/* Custom styles for the range slider */
input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so we can style it */
    width: 100%; /* Specific width is required for Firefox */
    background: transparent; /* Otherwise white in Chrome */
    border: 1px solid transparent;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: #efefeb; /* Change this to your desired track color */
    border-radius: 5px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid var(--primary-color); /* Border color of the thumb */
    height: 24px;
    width: 24px;
    border-radius: 50%; 
    background: var(--primary-color); /* Change this to your desired thumb color */
    cursor: pointer;
    margin-top: -8px; /* You need to specify a margin in Chrome, Safari, and Opera */
  }
  
  input[type="range"]:focus {
    outline: none; /* Removes the blue border when the slider is focused */
  }
  
  input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  input[type="range"]::-ms-fill-lower {
    background: var(--primary-color); /* Change this to your desired track color */
    border-radius: 5px;
  }
  
  input[type="range"]::-ms-fill-upper {
    background: var(--primary-color); /* Change this to your desired track color */
    border-radius: 5px;
  }
  
  input[type="range"]::-ms-thumb {
    border: 1px solid var(--primary-color); /* Border color of the thumb */
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--primary-color); /* Change this to your desired thumb color */
    cursor: pointer;
  }
  
  input[type="range"]:focus::-ms-fill-lower,
  input[type="range"]:focus::-ms-fill-upper {
    background: var(--primary-color); /* Change this to your desired track color */
  }

  .crop-item {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaçamento entre os elementos */
  }
  
  .crop-item select {
    flex: 2; /* Ajusta largura do dropdown */
  }
  
  .crop-item input {
    flex: 1; /* Ajusta largura do input */
  }
  
  .crop-item button {
    flex: 0; /* Mantém o botão pequeno */
    padding: 10px 10px;
  }
  

  

  @media only screen and (max-width: 600px) {
    .register-form-wrapper{
      flex-direction: column;
      position: absolute;
      top: 6rem;
    }

    .left-column img{
      width: 150px;
      margin-top: 1rem;
    }

    .register-title{
      margin: 0;
      padding: 0;
    }
    

    .left-column .subtitle{
      display: none;
    }

    .right-column form{
      flex-direction: column;
      
    }

    .input-group-left,.input-group-right{
      gap: 0.5rem;
      margin: 1rem 0rem;

    }
  }