
.map-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 0;
    justify-content: center;
  }

  .map-geocoder-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    position: relative;
    top: 0;
    left: 0;
    border-radius: 0.5rem;
    z-index: 0;
    border: 2px solid white;
   
    }

  .map-url-contributor{
    color: var(--primary-color);
    text-decoration: none;
  }
  

  .leaflet-popup {
    border-radius: 1rem;   
    text-align: center;
    justify-content: center;
    background: var(--focus-color);
    padding: 0.2rem;
  }

.leaflet-popup-close-button span { 
  background-color: var(--select-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -0.5rem;
  right: 0.8rem;
}

.leaflet-popup-close-button span:hover {
  background-color: var(--primary-color);
}

  
 
  .leaflet-popup-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0.25rem;
    margin: 0.5rem;
  }

  .leaflet-popup-content-wrapper {  
    margin-top: 1.25rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
   
  }

  .leaflet-popup-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0rem;
  }
  
 
  .custom-marker {
    position: relative;
    width: 40px; 
    height: 40px;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .custom-marker::after {
    content: '';
    position: absolute;
    bottom: -17Px; /* Adjust to move the point */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid transparent; /* Adjust size */
    border-right: 12px solid transparent; /* Adjust size */
    border-top: 18px solid var(--primary-color); /* Adjust size and color */
    z-index: -1;
  }
  

  #map1 .leaflet-tile-pane {
    /* -webkit-filter: grayscale(100%) brightness(1.2) saturate(1.2); */
    filter: grayscale(100%) brightness(1.15) saturate(0.5);
}


.map-popup-wrapper{
  background-color: white;
  padding: 0.5rem;
  justify-content: center;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.map1 {
  height: 100%;
  width: 100%;
}


.default-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;
}

.default-button:hover {
  background-color: var(--primary-color);
}

.leaflet-popup-content-wrapper {
  background: white;
  border-radius: 8px;
}

.leaflet-popup-content {
  text-align: left;
  margin: 0.5rem;
}
