.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.2rem 1rem;
    justify-content: baseline;
    align-items: center;
    vertical-align: middle;
    height: 3rem;
    background: white;
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: right;
}